import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

import "./modalkebijakan.scss";

class ModalKebijakanPrivacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrolledToBottom: false,
            buttonEnable: false
        };
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;
        if (bottom) {
            this.setState({ isScrolledToBottom: true });
        }
    };

    handleChecklist = (e) => {
        this.setState({
            buttonEnable: e.target.checked
        });
    };

    render() {
        const { isScrolledToBottom, buttonEnable } = this.state;
        return (
            <Modal size="lg" keyboard={false} style={{ zIndex: 10 }} backdrop="static" isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog modal-dialog-centered" tabIndex="-1">
                <ModalHeader>
                    <div className="text-center flex-fill">
                        <h5 className="modal-title text-left font-weight-bold">{this.props.isUpdate && 'Update'} Kebijakan Privasi</h5>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="container-pop-kebijakan" onScroll={this.handleScroll}>
                        <div className="jarak">
                            <div className="d-flex flex-row mt-2">
                                <h5 className="text-title-privacy">1.</h5>
                                <h5 className="ml-3 text-title-privacy">Latar Belakang</h5>
                            </div>
                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>1.1</p>
                                    <p className="ml-3">
                                        Kebijakan Privasi (“Kebijakan”) ini berlaku dan mengikat seluruh pengunjung portal Indofund baik melalui
                                        website (www.indofund.id) maupun aplikasi mobile (aplikasi Indofund) (“Portal”), khususnya anggota Indofund
                                        yang telah terdaftar, termasuk Anda. Dengan terus mengakses dan/atau menggunakan layanan yang tersedia pada
                                        Portal, maka Anda mengakui bahwa Anda telah membaca dan menyetujui Kebijakan ini.
                                    </p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>1.2</p>
                                    <p className="ml-3">
                                        Kebijakan ini dibuat agar Anda mengetahui perolehan, pengumpulan, pengolahan, penganalisisan, penyimpanan,
                                        penampilan, penyebarluasan, pembukaan akses dan pemusnahan atas data-data pribadi Anda yang kami lakukan,
                                        sebelum Anda memberikan data pribadi Anda.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-title-privacy mt-3">
                                <h5 className="text-title-privacy">2.</h5>
                                <h5 className="ml-3 text-title-privacy">Perolehan dan/atau Pengumpulan Informasi</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>2.1</p>
                                    <p className="ml-3">
                                        Kami mengumpulkan informasi dan data pribadi Anda (i) yang Anda berikan secara langsung kepada kami, (ii) yang
                                        terkait dengan penggunaan Portal oleh Anda, dan (iii) yang diberikan oleh pihak ketiga dengan persetujuan
                                        Anda.
                                    </p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>2.2</p>
                                    <p className="ml-3">
                                        Data pribadi Anda sebagaimana disebut dalam Angka 2.1 di atas termasuk, namun tidak terbatas pada:
                                    </p>
                                </div>
                                <div className="sub-text">
                                    <div className="d-flex flex-row">
                                        <p>(a)</p>
                                        <p className="ml-3">
                                            data identitas dan profil Anda, termasuk KTP, NPWP, nama lengkap, tempat tanggal lahir, jenis kelamin,
                                            alamat, nomor rekening, agama, pekerjaan, tujuan penggunaan, sumber dana, alamat email, nomor telepon,
                                            informasi keuangan, foto, latar belakang pendidikan dan pekerjaan, dan informasi terkait identitas dan
                                            profil lainnya;
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(b)</p>
                                        <p className="ml-3">
                                            pola atau kebiasaan Anda dalam penggunaan Portal, profil risiko Anda, portofolio pemberian pendanaan Anda,
                                            sejarah pencarian atau penggunaan layanan, transaksi yang Anda lakukan di Portal, dan informasi terkait
                                            pola atau kebiasaan lainnya;
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(c)</p>
                                        <p className="ml-3">
                                            alamat IP Anda, informasi log-in Anda, perangkat keras dan perangkat lunak (termasuk tipe dan versi
                                            browser) yang Anda gunakan, serta informasi teknis lainnya terkait penggunaan Portal oleh Anda.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p className="ml-3">
                                        (Informasi sebagaimana disebut dalam Angka 2.2 di atas selanjutnya disebut sebagai “Data Pribadi” Anda)
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-title-privacy mt-3">
                                <h5 className="text-title-privacy">3.</h5>
                                <h5 className="ml-3 text-title-privacy">Pengolahan dan Penganalisisan Informasi</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>3.1</p>
                                    <p className="ml-3">
                                        Kami menggunakan Data Pribadi Anda untuk (i) memverifikasi kelayakan Anda sebagai pengguna, (ii) memberikan
                                        akses layanan yang disediakan di Portal kepada Anda, (iii) menyelesaikan hak dan kewajiban kepada Anda sebagai
                                        pengguna Portal, (iv) mengawasi penggunaan layanan di Portal oleh Anda, (v) memproses dan mengelola akun Anda,
                                        (vi) memberikan otorisasi penggunaan akun Anda di Portal, (vii) mendeteksi, mencegah, dan menyangkal setiap
                                        tindakan melawan hukum terhadap atau oleh Anda, (viii) melakukan audit internal maupun external, (ix)
                                        melakukan pemasaran yang efektif dan relevan, dan (x) untuk hal-hal lainnya yang terkait dengan kegiatan usaha
                                        Indofund sebagai Penyelenggara Layanan Pendanaan Bersama berbasis Teknologi Informasi.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-title-privacy mt-3">
                                <h5 className="text-title-privacy">4.</h5>
                                <h5 className="ml-3 text-title-privacy">Penyimpanan Data</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>4.1</p>
                                    <p className="ml-3">
                                        Kami melakukan penyimpanan Data Pribadi Anda dengan teknologi enkripsi sesuai standar ISO 27001 untuk
                                        memastikan keamanan Data Pribadi Anda.
                                    </p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>4.2</p>
                                    <p className="ml-3">
                                        Data Pribadi Anda kami simpan paling tidak sampai dengan 5 tahun sejak Anda berakhir menjadi pengguna Portal
                                        kami, sesuai dengan persyaratan dalam peraturan perundang-undangan. Apabila Anda ingin agar Data Pribadi Anda
                                        dihapus sebelum itu, maka Anda dapat mengirimkan permohonan kepada Customer Service.
                                    </p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>4.3</p>
                                    <p className="ml-3">
                                        Data Pribadi Anda kami simpan pada pusat data kami serta pusat pemulihan bencana kami yang berlokasi di
                                        Indonesia.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-title-privacy mt-3">
                                <h5 className="text-title-privacy">5.</h5>
                                <h5 className="ml-3 text-title-privacy">Pengungkapan dan Pembukaan Akses</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>5.1</p>
                                    <p className="ml-3">
                                        Kami akan menjaga kerahasiaan Data Pribadi Anda. Namun demikian, Anda setuju bahwa kami dapat mengungkapkan
                                        Data Pribadi Anda kepada pihak yang dianggap perlu oleh kami yang terkait dengan kegiatan usaha kami sebagai
                                        Penyelenggara Layanan Pendanaan Bersama Berbasis Teknologi Informasi, termasuk namun tidak terbatas kepada:
                                    </p>
                                </div>
                                <div className="sub-text">
                                    <div className="d-flex flex-row">
                                        <p>(a)</p>
                                        <p className="ml-3">direktur, komisaris, atau karyawan Indofund;</p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(b)</p>
                                        <p className="ml-3">
                                            mitra serta penyedia jasa penunjang atas aktifitas usaha Indofund, termasuk mitra kerjasama channeling,
                                            penyedia payment gateway, penyedia sistem analisis psychometric, bank, penyelenggara sertifikasi
                                            elektronik untuk pembuatan tanda tangan elektronik, konsultan atau penyedia jasa penilaian kelayakan usaha
                                            dan kredit, konsultan hukum, konsultan keuangan, konsultan teknis dan konsultan pajak yang ditunjuk
                                            Indofund untuk memberikan jasa penunjang yang relevan;
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(c)</p>
                                        <p className="ml-3">
                                            Fintech Data Center (FDC) yang didirikan oleh Asosiasi Fintech Pendanaan Bersama Indonesia (AFPI), untuk
                                            keperluan pelaporan data kredit Anda sebagai Penerima pendanaan di Indofund;
                                        </p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(d)</p>
                                        <p className="ml-3">pihak-pihak lain yang terkait dengan kegiatan usaha Indofund; dan</p>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <p>(e)</p>
                                        <p className="ml-3">pihak yang berwenang sesuai dengan ketentuan peraturan perundang-undangan.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-title-privacy mt-3">
                                <h5 className="text-title-privacy">6.</h5>
                                <h5 className="ml-3 text-title-privacy">Perubahan Data Pribadi</h5>
                            </div>
                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>6.1</p>
                                    <p className="ml-3">Anda berkewajiban untuk menjaga keakurasian dari Data Pribadi Anda.</p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>6.2</p>
                                    <p className="ml-3">Anda dapat mengubah Data Pribadi terdaftar Anda melalui Portal.</p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>6.3</p>
                                    <p className="ml-3">
                                        Kami dapat melakukan tindakan-tindakan yang diperlukan untuk memastikan keakuratan dan kemutakhiran Data
                                        Pribadi Anda, termasuk dengan menghubungi Anda.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-title-privacy mt-3">
                                <h5 className="text-title-privacy">7.</h5>
                                <h5 className="ml-3 text-title-privacy">Data Pribadi Anak Bawah Umur</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>7.1</p>
                                    <p className="ml-3">
                                        Dalam hal terdapat Data Pribadi dari pengguna yang ternyata masuk dalam kategori anak di bawah 17 tahun, maka
                                        orang tua atau wali dari anak bawah umur tersebut dapat meminta Indofund untuk menghapus Data Pribadi anak
                                        tersebut dengan mengirimkan permohonan kepada Customer Service.
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-row text-title-privacy mt-3">
                                <h5 className="text-title-privacy">8.</h5>
                                <h5 className="ml-3 text-title-privacy">Perubahan Kebijakan Privasi</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p className="ml-3">
                                        Setiap perubahan atas Kebijakan ini akan tertera pada halaman ini, dan apabila dipandang perlu akan
                                        diberitahukan kepada seluruh pengguna terdaftar melalui email atau metode lainnya.
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex flex-row text-title-privacy mt-3">

                                <h5 className="text-title-privacy">9.</h5>
                                <h5 className="ml-3 text-title-privacy">Penonaktifan dan/atau Penghapusan Data Pribadi</h5>
                            </div>

                            <div className="sub-text">
                                <div className="d-flex flex-row">
                                    <p>9.1</p>
                                    <p className="ml-3">Pengguna dapat mengajukan permintaan untuk menonaktifkan dan/atau penghapusan data pribadi yang terdaftar atas namanya pada Portal dengan mengirim permintaan melalui Kontak Kami disertai dengan alasan-alasan Penonaktifan dan/atau Penghapusan Data Pribadi.</p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>9.2</p>
                                    <p className="ml-3">Proses Penonaktifan dan/atau Penghapusan Data Pribadi berarti data pribadi tidak akan digunakan untuk keperluan operasional atau promosi, namun untuk data data yang akan mempengaruhi perhitungan ataupun sistem vital Portal, maka data pribadi anda akan dianomisasi agar tidak dapat kembali digunakan sebagai pengenal kepada anda.</p>
                                </div>
                                <div className="d-flex flex-row">
                                    <p>9.3</p>
                                    <p className="ml-3">
                                        Penonaktifan dan/atau pemutusan dan penghapusan data pribadi dilakukan dengan memperhatikan kewajiban Portal untuk melakukan penyimpanan data pribadi sesuai batas waktu yang ditentukan dalam peraturan perundang-undangan yang berlaku sejak tanggal penonaktifan dan/atau penghapusan data pribadi tersebut.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="custom-control custom-checkbox filter-input mt-4">
                        <input
                            onChange={this.handleChecklist.bind(this)}
                            type="checkbox"
                            className="custom-control-input"
                            id="perjanjian"
                            disabled={!isScrolledToBottom}
                        />
                        <label className="custom-control-label" htmlFor="perjanjian">
                            Saya Telah Membaca, Memahami Dan Menyetujui Kebijakan Privasi
                        </label>
                    </div>

                    <div className="button-container-data">
                        <Button
                            disabled={this.props.isLoading || !buttonEnable}
                            color="primary"
                            className="ml-3"
                            onClick={() => this.props.onClick()}>
                            {this.props.isLoading ? (
                                <div className="d-flex align-items-center">
                                    <span className="loader mr-2"></span>
                                    <p className="m-0">Mohon Tunggu</p>
                                </div>
                            ) : (
                                "Lanjutkan"
                            )}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}
export default ModalKebijakanPrivacy;
