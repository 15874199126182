import React, { Component } from "react";
import { BrowserRouter, NavLink } from "react-router-dom";
import Menu from "../template/lender/SidebarMenu";
import Routes from "../../routes/RouteLender";
import Swal from "sweetalert2";
import Masters from "../template/lender/Master";
import Loader from "react-loader-spinner";
import { DipayLogin, getUser as UserDipay } from "react-dipay-web-sdk";
import { AcceptTerm, getUser } from "../../redux/action/User";
import { LenderId, checkLenderUpdateYearly, updateDataLenderYearly } from "../../redux/action/Lender";
import { connect } from "react-redux";
import { LinkAccount } from "../../redux/action/Dipay";
import "react-dipay-web-sdk/dist/index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import axios from "axios";
import { TKB90 } from "../../redux/action/User";
import ModalPengkinianData from "./popup_pengkinian_data/ModalPengumuman";
import ModalKebijakanPrivacy from "../guest/welcome/modalPrivacy/ModalKebijakanPrivacy";
import { ShowElementContext } from "../../context/ShowElementContextProvider";

const url = process.env.REACT_APP_BASSE_API;

class Lender extends Component {
  static contextType = ShowElementContext; // assign static contextType
  constructor(props) {
    const dipay = JSON.parse(process.env.REACT_APP_DEV_DIPAY);
    super(props);
    this.state = {
      jenis_pengguna: "",
      email: "",
      nama_pengguna: "",
      saldo: "",
      lender: "",
      nama_lender: "",
      kyc: "",
      update_lender: "",
      loading: true,
      no_hp: "",
      bank: [],
      va: "",
      nicepay_register: "",
      dipay_key: "",
      openDipay: false,
      setOpenDipay: false,
      saldoDipay: "",
      devDipay: dipay,
      digisign_kyc: "",
      userDigisign: "",
      tkb: { tkb0: '', tkb30: '', tkb60: '', tkb90: '' },
      isOpenTkbModal: false,
      userNameDipay: "",
      userTlpDipay: "",
      loadingDipay: true,
      identityUser: "",
      adminVerifLog: [],
      modal: false,
      setOpenModal: false,
      statusCheckYearly: false,
      id_pendidikan: "",
      id_pekerjaan: "",
      id_bidang_pekerjaan: "",
      pengalaman_kerja: "",
      pendapatan: "",
      sumber_dana: "",
      lender_personal: 1,
      modalKebijakan: false,
      term_timestamp: false
    };
  }

  toggleModalPengkinianData() {
    this.setState({
      modal: !this.state.modal,
      setModal: !this.state.setModal
    });
  }

  toggleKebijakanPrivacyCheck() {
    this.setState({
      modalKebijakan: !this.state.modalKebijakan
    });
  }
  toggleTkbModal() {
    this.setState({ isOpenTkbModal: !this.state.isOpenTkbModal })
  }
  updateLenderYearly = async (value) => {
    await this.props
      .dispatch(updateDataLenderYearly(this.props.user.userList.lender_id._id, value))
      .then(() => {
        this.toggleModalPengkinianData();
        Swal.fire({
          title: `<p style="font-size:20px;">Terimakasih telah Mengonfirmasi Pengkinian Data!</p>`,
          text: "Data anda dinyatakan Terbaru tidak ada Data yang perlu di lakukan Pembaruan",
          icon: "success",
          buttons: "ok"
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => {
        this.toggleModalPengkinianData();
        Swal.fire({
          title: "Gagal",
          text: "Terjadi Kesalahan silakan coba kembali",
          icon: "error",
          buttons: "ok"
        }).then(() => {
          window.location.reload();
        });
      });
  };

  handleAcceptTerm = async () => {
    await this.props.dispatch(AcceptTerm()).finally(() => {
      this.toggleKebijakanPrivacyCheck();
    });
  };

  //loading
  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  wait = async (milliseconds = 1000) => {
    await this.sleep(milliseconds);
    this.setState({
      loading: false
    });
  };
  logout = (e) => {
    // console.log(this.props)
    e.preventDefault();
    Swal.fire({
      title: "Logout",
      text: "Yakin keluar dari akun anda ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Keluar sekarang"
    }).then((result) => {
      if (result.value) {
        axios.post(
          `${url}/user/logout`,
          { accessToken: localStorage.accessToken },
          {
            headers: { "x-auth-token": localStorage.jwtToken }
          }
        );
        localStorage.removeItem("id");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("email");
        localStorage.removeItem("kode_pengguna");
        localStorage.removeItem("nama_pengguna");
        localStorage.removeItem("no_hp");
        localStorage.removeItem("justOnce");
        localStorage.removeItem("masuk");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("kyc");
        localStorage.removeItem("verif");
        window.location.reload();
        window.location.href = "/";
      }
    });
  };
  async componentDidMount() {
    localStorage.setItem("masuk", "1");
    window.scrollTo(0, 0);
    //loading
    this.wait(0.5);

    await this.props.dispatch(getUser(localStorage.id));
    await this.props.dispatch(LenderId(this.props.user.userList.lender_id._id));

    this.setState({
      lender_personal: this.props.user.userList.lender_id.jenis_pengguna,
      update_lender: this.props.lender.lenderDetail.update_lender,
      term_timestamp: this.props.user.userList.term_timestamp
    });


    // FEATURE UPDATE DATA TAHUNAN
    if (this.state.lender_personal === 1 && this.state.update_lender) {
      await this.props.dispatch(checkLenderUpdateYearly(this.props.user.userList.lender_id._id));
      this.setState({
        statusCheckYearly: this.props.lender.lenderYearly.result.includes("Lender belum pernah"),
      });
      this.setState({
        id_pendidikan: this.props.lender.lenderDetail.ojkJson.pendidikan.kode,
        id_pekerjaan: this.props.lender.lenderDetail.ojkJson.pekerjaan.kode,
        id_bidang_pekerjaan: this.props.lender.lenderDetail.ojkJson.bidangPekerjaan.kode,
        pengalaman_kerja: this.props.lender.lenderDetail.ojkJson.pengalamanKerja.kode,
        pendapatan: this.props.lender.lenderDetail.ojkJson.pendapatan.kode,
        sumber_dana: this.props.lender.lenderDetail.sumber_dana,
      });
    }
    await this.props.dispatch(TKB90());
    this.setState({
      tkb: { tkb0: this.props.user.TKB.tkb0, tkb30: this.props.user.TKB.tkb30, tkb60: this.props.user.TKB.tkb60, tkb90: this.props.user.TKB.tkb90 }
    });

    this.setState({
      email: this.props.user.userList.email,
      nama_pengguna: this.props.user.userList.nama_pengguna,
      jenis_pengguna: this.props.lender.lenderDetail.jenis_pengguna,
      saldo: this.props.user.userList.va_saldo.nilai_saldo,
      lender: this.props.lender.lenderDetail,
      nama_lender: this.props.lender.lenderDetail.nama_lender,
      kyc: this.props.lender.lenderDetail.admin_kyc,
      id: this.props.lender.lenderDetail._id,
      update_lender: this.props.lender.lenderDetail.update_lender,
      no_hp: this.props.user.userList.no_handphone,
      bank: this.props.user.userList.bank,
      va: this.props.user.userList.va_saldo.va_saldo,
      nicepay_register: this.props.user.userList.nicepay_register,
      dipay_key: this.props.user.userList.dipay_key,
      digisign_kyc: this.props.user.userList.kyc, //dari admin ke digisign
      userDigisign: this.props.user.userList.userAktivasi, //user mau verifikasi email
      identityUser: parseInt(this.props.lender.lenderDetail.no_ktp),
      adminVerifLog: this.props.lender.lenderDetail.admin_verif_log
    });
    if (!localStorage.justOnce) {
      localStorage.setItem("justOnce", "true");
      //   window.location.reload();
    }

    if (this.state.dipay_key !== undefined) {
      this.userDipay();
    }

    //pengkinian data tahunan
    if (this.state.statusCheckYearly && this.state.kyc) {
      this.toggleModalPengkinianData();
    }
    
    if (!this.state.term_timestamp) {
      this.setState({
        modalKebijakan: true
      });
    }
  }

  renderNotifBox = () => {
    const lenLog = this.state.adminVerifLog.length;
    let view = "";
    let messageReject = "";
    let isRejetedVerif = false;

    if (lenLog > 0) {
      isRejetedVerif = this.state.adminVerifLog[lenLog - 1].status === "failed";
      if (isRejetedVerif) {
        messageReject = this.state.adminVerifLog[lenLog - 1].reason;
      }
    }

    if (this.state.update_lender === false && this.state.kyc === false && lenLog === 0) {
      view = (
        <div className="alert-box">
          <span className="mr-2">Silahkan lengkapi data diri & rekening bank untuk memberikan pendanaan</span>
          <NavLink
            to={{
              pathname: "/lender/akun/" + this.state.lender._id,
              state: {
                jenis: `${this.state.jenis_pengguna}`
              }
            }}
            className="btn btn-light ml-auto text-primary px-4 font-weight-semi-bold">
            Lengkapi
          </NavLink>
        </div>
      );
    } else if (this.state.update_lender === true && this.state.kyc === false) {
      view = (
        <div className="alert-box">
          <span className="mr-2">Data anda sedang di verifikasi oleh admin</span>
        </div>
      );
    } else if (this.state.update_lender === false && isRejetedVerif) {
      view = (
        <div className="alert-box alert-box-reject">
          <span className="mr-2">{messageReject}</span>
          <div>
            <NavLink
              to={{
                pathname: "/lender/akun/" + this.state.lender._id,
                state: {
                  jenis: `${this.state.jenis_pengguna}`
                }
              }}
              className="btn btn-light ml-auto text-danger px-4 font-weight-semi-bold">
              Ubah Data
            </NavLink>
          </div>
        </div>
      );
    }
    return view;
  };

  //dipay aktivasi
  toggleModalAktivasi() {
    this.setState({
      openDipay: !this.state.openDipay,
      setOpenDipay: !this.state.setOpenDipay
    });
  }
  LinkDipay = async (secretkey) => {
    //send backend
    await this.props
      .dispatch(
        LinkAccount({
          dipay_key: secretkey
        })
      )
      .then((res) => {
        this.setState({
          openDipay: !this.state.openDipay,
          setOpenDipay: !this.state.setOpenDipay
        });
        Swal.fire({
          title: "Berhasil",
          text: "Akun Dipay Anda berhasil koneksi dengan akun Indofund ",
          icon: "success",
          buttons: "ok"
        }).then(() => {
          window.location.reload();
        });
        //   console.log(res)
      })
      .catch((err) => {
        //   console.log(err)
      });
  };

  userDipay = async () => {
    //get user from Dipay
    await UserDipay("indofund", this.state.dipay_key, this.state.devDipay)
      .then((res) => {
        this.setState({
          loadingDipay: false,
          saldoDipay: res.balance.primary, //change saldo dipay
          userNameDipay: res.firstName + " " + res.lastName,
          userTlpDipay: res.phoneNumber
        });
      })
      .catch((err) => {
        //  console.log(err)
      });
  };

  handleUpdateData(params) {
    switch (params) {
      case "accepted":
        this.updateLenderYearly({
          id_pendidikan: this.state.id_pendidikan,
          id_pekerjaan: this.state.id_pekerjaan,
          id_bidang_pekerjaan: this.state.id_bidang_pekerjaan,
          pengalaman_kerja: this.state.pengalaman_kerja,
          pendapatan: this.state.pendapatan,
          sumber_dana: this.state.sumber_dana
        });
        break;
      case "update":
        this.toggleModalPengkinianData();
        break;
      default:
        break;
    }
  }
  render() {
    //loading
    const { isShowSaldo, isShowSaldoDipay, toggleIsShowSaldo, toggleIsShowSaldoDipay } = this.context;
    if (this.state.loading) return <Loader type="ThreeDots" color="#FF7A00" height={100} width={100} className="loading-dots" />;

    return (
      <BrowserRouter>
        <Masters
          onLogout={(e) => this.logout(e)}
          lender={this.state.lender}
          nama_pengguna={this.state.nama_pengguna}
          jenis={this.state.jenis_pengguna}
          email={this.state.email}
          saldo={this.state.saldo}
          toggleModalAktivasi={this.toggleModalAktivasi.bind(this)}
          dipay_key={this.state.dipay_key}
          isShowSaldo={{ saldo: isShowSaldo, dipay: isShowSaldoDipay }}
          toggleIsShowSaldo={toggleIsShowSaldo}
          toggleIsShowSaldoDipay={toggleIsShowSaldoDipay}
          saldoDipay={this.state.saldoDipay}
          tkb={this.state.tkb}
          isOpenTkbModal={this.state.isOpenTkbModal}
          toggle={this.toggleTkbModal.bind(this)}
          userDigisign={this.state.userDigisign}
        />
        <section className="with-pad">
          <div className="container">
            <div className="row">
              <Menu
                nama_pengguna={this.state.nama_pengguna}
                lender={this.state.lender}
                jenis={this.state.jenis_pengguna}
                email={this.state.email}
                saldo={this.state.saldo.toLocaleString("IN")}
                id={localStorage.id_pengguna}
                onLogout={(e) => this.logout(e)}
                isShowSaldo={{ saldo: isShowSaldo, dipay: isShowSaldoDipay }}
                toggleIsShowSaldo={toggleIsShowSaldo}
                toggleIsShowSaldoDipay={toggleIsShowSaldoDipay}
                loading={this.props.user.isLoadingGetUser}
                toggleModalAktivasi={this.toggleModalAktivasi.bind(this)}
                dipay_key={this.state.dipay_key}
                saldoDipay={this.state.saldoDipay}
                loadingLender={this.props.isLoadingLender}
              />
              <div className="col-12 col-lg-9">
                {this.renderNotifBox()}
                <Routes
                  saldo={this.state.saldo}
                  nama_pengguna={this.state.nama_pengguna}
                  no_hp={this.state.no_hp}
                  email={this.state.email}
                  userBank={this.state.bank}
                  va={this.state.va}
                  nicepay_register={this.state.nicepay_register}
                  loadingUser={this.props.user.isLoadingGetUser}
                  dipay_key={this.state.dipay_key}
                  id={this.state.id}
                  digisign_kyc={this.state.digisign_kyc}
                  userDigisign={this.state.userDigisign}
                  userNameDipay={this.state.userNameDipay}
                  userTlpDipay={this.state.userTlpDipay}
                  loadingUserDipay={this.state.loadingDipay}
                  adminVerifLog={this.state.adminVerifLog}
                  update_lender={this.state.update_lender}
                  statusCheckYearly={this.state.statusCheckYearly}
                  admin_kyc={this.state.kyc}
                  jenis={this.state.jenis_pengguna}
                  lender={this.state.lender}
                />
              </div>
            </div>
          </div>
        </section>

        <DipayLogin
          clientId="indofund" // Contact us for your client ID
          open={this.state.openDipay}
          onClose={this.toggleModalAktivasi.bind(this)}
          onSuccess={(data) => this.LinkDipay(data.secretKey)}
          dev={this.state.devDipay}
          identificationNumber={this.state.identityUser}
        //data.balance.primary
        // onSuccess={data => console.log(data)}X
        />
        {/* //pengkinian data tahunan */}
        <ModalKebijakanPrivacy
          isOpen={this.state.modalKebijakan}
          toggle={this.toggleKebijakanPrivacyCheck.bind(this)}
          isLoading={this.props.user.isLoadingTerm}
          onClick={this.handleAcceptTerm.bind(this)}
          isUpdate={true}
        />
        <ModalPengkinianData
          onClick={this.handleUpdateData.bind(this)}
          isOpen={this.state.modal}
          toggle={this.toggleModalPengkinianData.bind(this)}
          id={this.state.lender._id}
          jenis={this.state.jenis_pengguna}
          isLoding={this.props.lender.isLoadingudapteYerly}
        />

      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    lender: state.lender,
    dipay: state.dipay
  };
};
export default connect(mapStateToProps)(Lender);
